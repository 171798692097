import React, { useContext, useEffect, useState, useCallback } from 'react'
import { pathOr } from 'ramda'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Img from 'gatsby-image'

import { Layout } from '_templates'
import { SEO, ViewportObserver, OpeningsSection } from '_organisms'
import {
  Hero,
  ValueCard,
  ImpactCard,
  ImageSlider,
  QuoteContainer,
  BenefitCard,
  DidntFind,
  EqualOpportunities,
} from '_molecules'
import { AppContext } from '_context'
import { Container, Heading, BlueText, Text, Image } from '_atoms'
import { getRecruiterboxPositions } from '_utils/requests'
import { scrollTo } from '_utils/scroll'
import variables from '_config/css-variables'
import BrandSymbol from '_images/svgs/brand-symbol.inline.svg'

import styles from './styles.module.css'

const MOSAICO_COLORS = {
  // 5 columns
  default: [
    'light',
    'medium',
    'dark',
    'light',
    'medium',
    'medium',
    'dark',
    'light',
    'medium',
    'dark',
    'dark',
    'light',
    'medium',
    'dark',
    'light',
  ],
  // 4 columns
  1024: [
    'light',
    'medium',
    'dark',
    'light',
    'medium',
    'dark',
    'light',
    'medium',
    'light',
    'medium',
    'dark',
    'light',
    'medium',
    'dark',
    'light',
  ],
  // 3 columns
  768: [
    'light',
    'medium',
    'dark',
    'medium',
    'dark',
    'light',
    'light',
    'medium',
    'dark',
    'medium',
    'dark',
    'light',
    'light',
    'medium',
    'dark',
  ],
  // 2 columns
  480: [
    'light',
    'medium',
    'dark',
    'light',
    'medium',
    'dark',
    'light',
    'medium',
    'dark',
    'light',
    'medium',
    'dark',
    'light',
    'medium',
    'dark',
  ],
}

const Careers = ({ data }) => {
  const [openPositions, setPositions] = useState([])
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const { careersTitle, careersDescription, careersThumbnail } = data.contentfulPageMetatags
  const allValues = data.allContentfulValues.nodes
  const gptwData = data.contentfulGptw
  const allImpacts = data.allContentfulImpacts.nodes
  const sliderImpactImages = data.allContentfulImpactImages.nodes
  const glassdoorMentions = data.allContentfulGlassdoorMentions.nodes
  const allBenefits = data.allContentfulBenefits.nodes
  const { careersDesktop, careersMobile } = data.contentfulHeroImages
  const [y, setY] = useState(0)
  // clickable area to toggle off all value cards on mobile
  const [valuesContainer, setValuesContainer] = useState(null)
  const [valuesClickPath, setValuesClickPath] = useState([])

  // benefits colors don't update on page resize, only on first load
  const [benefitsColor, setBenefitsColor] = useState([])

  const getBenefitsColorPattern = () => {
    const pageWidth = window ? window.innerWidth : 0

    setBenefitsColor(MOSAICO_COLORS.default)

    Object.entries(MOSAICO_COLORS)
      .reverse()
      .forEach(([key, values]) => {
        if (Number(key) && pageWidth <= Number(key)) setBenefitsColor(values)
      })
  }

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    if (y <= 90) {
      setNavbarColor('transparent')
    }
  }, [setNavbarColor, y])

  useEffect(() => {
    if (valuesContainer) {
      valuesContainer.addEventListener('click', ({ path }) => {
        setValuesClickPath(path)
      })
    }
  }, [valuesContainer])

  useEffect(() => {
    getBenefitsColorPattern()

    getRecruiterboxPositions()
      .then(({ data: jobs }) => {
        const positions = pathOr([], ['objects'], jobs).filter(
          position => position.title !== 'Other'
        )
        setPositions(positions)
      })
      .catch(() => {
        setPositions([])
      })
  }, [])

  const renderValueCard = useCallback(
    items => {
      return items.map((value, index) => {
        const icon = value.icon.file.url
        return (
          <ValueCard
            position={index > 1 ? 'bottom' : 'top'}
            side={index / 2 === 0 ? 'left' : 'right'}
            key={value.id}
            icon={icon}
            title={value.title}
            description={value.description}
            clickEventPath={valuesClickPath}
          />
        )
      })
    },
    [valuesClickPath]
  )

  const sliderText = (
    <ul className={styles.heroSlider}>
      <li>
        <FormattedMessage id="careers.hero.slider.first" />
      </li>
      <li>
        <FormattedMessage id="careers.hero.slider.second" />
      </li>
      <li>
        <FormattedMessage id="careers.hero.slider.third" />
      </li>
      <li>
        <FormattedMessage id="careers.hero.slider.first" />
      </li>
    </ul>
  )

  const renderImpactCards = () =>
    allImpacts.map(value => {
      const icon = value.icon.file.url
      return (
        <ImpactCard
          key={value.title}
          icon={icon}
          title={value.title}
          description={value.description}
        />
      )
    })

  return (
    <Layout>
      <SEO
        title={careersTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={careersDescription || 'Web &amp; Mobile App Design and Development Company'}
        thumbnail={careersThumbnail.file.url}
      />
      <Hero
        title="careers.hero.title"
        subTitle="careers.hero.subTitle"
        buttonText="careers.hero.button"
        buttonOnClick={() => scrollTo('positions')}
        heroImageDesktop={careersDesktop}
        heroImageMobile={careersMobile}
        hasScrollArrow
        className={styles.hero}
        sliderText={sliderText}
      />

      <div className={styles.content}>
        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-primary'])
            setNavbarLight(false)
          }}
        />

        <section className={styles.valuesSection}>
          <Container>
            <div className={styles.valuesContainer}>
              <Heading type="h2" className={styles.valuesTitle}>
                <FormattedMessage id="careers.section.values.titleFisrtWord" />
                <strong>
                  {' '}
                  <FormattedMessage id="careers.section.values.titleBold" />{' '}
                </strong>
                <FormattedMessage id="careers.section.values.title" />
                <BlueText squared>.</BlueText>
              </Heading>
              <div
                className={styles.valuesItensContainer}
                ref={el => {
                  if (el) setValuesContainer(el)
                }}
              >
                <div className={styles.valuesRowTop}>{renderValueCard(allValues.slice(0, 2))}</div>
                <div className={styles.valuesRowTop}>{renderValueCard(allValues.slice(2, 4))}</div>
              </div>
            </div>
          </Container>
        </section>
        <section className={styles.gptwSection}>
          <div className={styles.gptwTextContainer}>
            <Heading type="h2" className={styles.gptwTitle}>
              <FormattedMessage id="careers.section.gptw.titleFirstPart" />{' '}
              <BlueText>{gptwData.position}</BlueText>{' '}
              <FormattedMessage id="careers.section.gptw.titleLastPart" />
            </Heading>
            <Text className={styles.employeesTotal}>
              {gptwData.category} – {gptwData.year}
            </Text>
          </div>
          <Img
            fluid={gptwData.image.fluid}
            alt={gptwData.image.fluid.description}
            className={styles.gptwImage}
          />
        </section>
        <section className={styles.impactSection}>
          <Container>
            <Heading type="h2" className={classnames(styles.title, styles.impactTitle)} bold>
              <FormattedMessage id="careers.section.impact.title" /> <BlueText squared>.</BlueText>
            </Heading>
            <div className={styles.impactGrid}>{renderImpactCards()}</div>
          </Container>
          <div className={styles.slider}>
            <ImageSlider id="impact-slider" className={styles.sliderContent}>
              {sliderImpactImages.map(node => (
                <Image alt={node.image.description} {...node.image.fluid} />
              ))}
            </ImageSlider>
          </div>
        </section>

        <section className={styles.glassdoorQuotesSection}>
          <Container>
            <Heading type="h4" className={styles.glassdoorQuotesSectionTitle}>
              <FormattedMessage id="careers.glassdoorQuotes.text1" />
              <strong>
                {' '}
                <FormattedMessage id="careers.glassdoorQuotes.text2" />
              </strong>
            </Heading>
          </Container>

          <QuoteContainer
            items={glassdoorMentions}
            isMultiple
            className={styles.quoteContainer}
            iconsClass={styles.glassdoorQuotesSectionIcons}
            isCenter
          />
        </section>
        <section className={styles.benefitsSection}>
          <Container>
            <Heading type="h2" className={classnames(styles.title, styles.benefitsTitle)}>
              <FormattedMessage id="careers.benefits.title" /> <BlueText squared>.</BlueText>
            </Heading>
          </Container>
          <div className={styles.benefitsGrid}>
            {allBenefits.map((item, index) => (
              <BenefitCard
                key={item.id}
                name={item.name}
                color={benefitsColor[index]}
                icon={item.icon}
                className={styles.benefitsCard}
              />
            ))}
            <div className={styles.benefitsFill}>
              <BrandSymbol />
            </div>
          </div>
        </section>
        <section className={styles.openingsSection} id="positions">
          <Container>
            {openPositions.length > 0 ? (
              <>
                <Heading type="h2" className={classnames(styles.title, styles.openingsTitle)}>
                  <FormattedMessage id="careers.openings.title" /> <BlueText squared>.</BlueText>
                </Heading>
                <OpeningsSection jobs={openPositions} />
              </>
            ) : (
              <OpeningsSection.Empty />
            )}
          </Container>
        </section>
        <section>
          <DidntFind />
        </section>
        <section>
          <EqualOpportunities />
        </section>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query CareersPage($locale: String) {
    contentfulPageMetatags(fields: { localeKey: { eq: $locale } }) {
      careersDescription
      careersTitle
      careersThumbnail {
        file {
          url
        }
      }
    }
    allContentfulValues(
      filter: { fields: { localeKey: { eq: $locale } } }
      sort: { order: ASC, fields: updatedAt }
      limit: 4
    ) {
      nodes {
        title
        description
        id
        icon {
          file {
            url
          }
        }
      }
    }
    contentfulGptw(fields: { localeKey: { eq: $locale } }) {
      category
      year
      position
      image {
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
    allContentfulImpacts(
      filter: { fields: { localeKey: { eq: $locale } } }
      sort: { order: ASC, fields: updatedAt }
      limit: 3
    ) {
      nodes {
        title
        id
        description
        icon {
          file {
            url
          }
        }
      }
    }
    allContentfulImpactImages(filter: { fields: { localeKey: { eq: $locale } } }) {
      nodes {
        id
        image {
          description
          fluid(quality: 100) {
            src
            srcSet
          }
        }
      }
    }
    allContentfulGlassdoorMentions(filter: { fields: { localeKey: { eq: $locale } } }) {
      nodes {
        id
        text
        stars
      }
    }
    allContentfulBenefits(filter: { fields: { localeKey: { eq: $locale } } }) {
      nodes {
        id
        name
        icon {
          description
          file {
            url
          }
        }
      }
    }
    contentfulHeroImages(fields: { localeKey: { eq: $locale } }) {
      careersDesktop {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      careersMobile {
        description
        fluid(quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
Careers.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default Careers
